import * as React from "react";
import Layout from "../components/Layout";
// markup
const IndexPage = () => {
  return (
    <Layout>
      <main>
        <h1> </h1>
      </main>
    </Layout>
  );
};

export default IndexPage;
